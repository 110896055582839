require([
    'jquery'
  ],
  ($) => {
    'use strict';

    const footerMenu = (() => {
		const $toggleElemList = $('.footer-menu__title');

		const toggleExpanded = (e) => {
			if (window.innerWidth < 600) {
				$(e.target).closest('.footer-menu__item').toggleClass('footer-menu--expanded');
			}
		};

		const attachEvents = () => $toggleElemList.on('click', toggleExpanded);

		const init = () => {
			attachEvents();
		};

		return {
			init
		};

	})();

	footerMenu.init();
});
