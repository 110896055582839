require([
    'jquery'
  ],
  ($) => {
	"use strict";

	const calendar = (() => {
		const $productList = $('.product-list-calendar .product-items');
		const activeClass = 'active';
		const visibleClass = 'visible';
		const specialClass = 'special';
		const readyClass = 'items_ready';
		const animationClass = 'animation-on';
		const freeDeliveryDayIndex = 9;
		const year = 2021;
		const month = 11;
		const text = {
			title: 'Dzień Darmowej Dostawy',
			description: 'Wszystkie zakupy dokonane przez klientów 10.12.2020 będą realizowane bez kosztów dostawy. Promocja dotyczy całego asortymentu dostępnego na sklep.tiptopol.pl'
		};

		const getToday = () => {
			const today = new Date();
			return {
				day: today.getDate(),
				month: today.getMonth(),
				year: today.getFullYear()
			};
		};

		const formatlabels = (dayIndex) => {
			if (dayIndex < 0) {
				return null;
			}
			const day = dayIndex + 1;
			const displayMonth = month + 1;
			const dayString = day > 9 ? `${day}` : `0${day}`;
			const date = new Date(year, month, day);
			const weekday = date.toLocaleString('pl-PL', { weekday: 'short' });
			return {
				weekday,
				date: `${dayString}.${displayMonth}`,
			};
		};

		const createSpecialBox = ($items, specialDayIndex, currentDay) => {
			const item = $items && $items[0] && $items[0].cloneNode(true);
			if (!item || specialDayIndex < 0) {
				return;
			}
			item.removeAttribute('data-sku');
			const labels = formatlabels(specialDayIndex);
			if (labels) {
				item.dataset.date = labels.date;
				item.dataset.weekday = labels.weekday;
			}
			const isActive = currentDay === specialDayIndex + 1;
			const isVisible = specialDayIndex < currentDay;
			isActive ? item.classList.add(activeClass) : item.classList.remove(activeClass);
			isVisible ? item.classList.add(visibleClass) : item.classList.remove(visibleClass);

			$(item).append(`<div class="item-content"><p class="item-title">${text.title}</p><p class="item-description">${text.description}<p><div>`);
			item.classList.add(specialClass);
			$items[specialDayIndex].before(item);
		};

		const isPromotionActive = () => {
			const today = getToday();
			return today.year === year && today.month === month;
		};

		const openItem = (e) => {
			const item = e.target;
			if (item && item.classList) {
				item.classList.add(animationClass);
				setTimeout(() => {
					item.classList.remove(animationClass);
					item.classList.add(visibleClass);
				}, 350);
			}
		};

		const addListeners = () => {
			$productList.on('click', openItem);
		};

		const getProductIds = ($items, specialIndex) => {
			const ids = $items.toArray().map((item) => item.dataset.sku) || [];
			ids.splice(specialIndex, 0, specialClass);
			return ids;
		};

		const initItems = () => {
			const currentDay = getToday().day;
			const $items = $productList.find('.item');
			const products = getProductIds($items, freeDeliveryDayIndex);
			const specialDayIndex = products.indexOf(specialClass);

			$items.each((_i, item) => {
				const promotionDayIndex = products.indexOf(item.dataset.sku);
				const promotionDay = promotionDayIndex + 1;
				const isVisible = promotionDay && promotionDay <= currentDay;
				const isActive = promotionDay === currentDay;
				const labels = formatlabels(promotionDayIndex);

				if (labels) {
					item.dataset.date = labels.date;
					item.dataset.weekday = labels.weekday;
				}

				if (isActive) {
					item.classList.add(activeClass);
					item.classList.add(visibleClass);
				}

				if (isVisible && isPromotionActive()) {
					item.classList.add(visibleClass);
				}
			});

			createSpecialBox($items, specialDayIndex, currentDay);
			$productList.addClass(readyClass);
		};

		const init = () => {
			if (!$productList) {
				return;
			}
			initItems();
			addListeners();
		};

		return {
			init
		};

	})();

	calendar.init();

});
