require([
    'jquery', 'domReady!'
  ],
  ($) => {

	'use strict';

	const distributorsMap = (() => {
		const $appendTo = $('.cms-siec-dystrybucji .column.main .std');
		const $regionList = $appendTo.find('.region-list');
        let activeRegion = $regionList.find('.active').index();

		const getMap = () => {
			return $.ajax({
				url: require.toUrl('images/maps/distributors.svg'),
				dataType: 'text'
			});
		};

		const getLabels = $regionList => {
			const labels = $('<ul class="region-labels list-unstyled"></ul>');
			$regionList.find('h2').each((_i, item) => {
				$('<li>').text(item.textContent).appendTo(labels);
			});

			return labels;
		};

		const setActiveClass = el => {
			$(el).addClass('active');
			$(el).siblings().removeClass('active');
		};

		const updateRegion = list => {
            setActiveClass($(list).eq(activeRegion));
		};

		const addEvents = () => {
			$appendTo.on('click', '.region', function() {
				activeRegion = $(this).index('.region');
                setActiveClass(this);
				updateRegion($regionList.find('li'));
			});
		};

		const appendMap = map => {
			const $wrapper = $('<div class="regions-map"></div>');
            $wrapper.append(map);
            $wrapper.append(getLabels($regionList));
			$appendTo.append($wrapper);
			updateRegion('.regions-map .region');
			addEvents();
		};

		const init = () => {
            if (!$appendTo.length) {
                return;
            }

            getMap(appendMap).then((map => appendMap(map)));
		};

		return {
			init
		};

	})();

	distributorsMap.init();

});
