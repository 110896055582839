require([
        'jquery'
    ],
    ($) => {

        'use strict';

        const account = (() => {
            const $toggler = $('.account .invoices .credit-toggler');
            const init = () => {
                $toggler.on('click', function (){
                    const $crediNotes = $(this).next('.cm-invoices');
                    $crediNotes.slideToggle();
                });
            };

            return {
                init
            };

        })();

        account.init();

    });
