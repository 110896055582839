require([
    'jquery', 'maskedinput'
  ],
  ($) => {

    const maskInputs = (() => {

        const selectors = [
            // '#contact-form-zip',
            // '.validate-zip-international',
            // '#checkout-billing-zip-code',
            // '#checkout-shipping-zip-code',
            // '#consultation-postcode',
        ];

        const addMasking = () => {
            selectors.forEach((selector) => {
                $(selector).mask('99-999');
            });
        };

        const init = () => {
            $(document).ready(() => addMasking(selectors));
        };

        return {
            init
        };

    })();

    maskInputs.init();
});
