require([
    'jquery', 'tippy', 'stickyjs', 'dotdotdot', 'lazysizes'
  ],
  ($, tippy, Sticky) => {
    'use strict';

	const common = (() => {
        const dotdotSelectors = [
            '.products-grid .product-item-name a',
            '.article-short',
            '.post-list .post-title',
            '.skip-account .label'
        ];

		const truncateText = (selector) => {
			if (typeof $(selector).dotdotdot === 'function') {
				$(selector).dotdotdot({
					truncate: "word"
				});
			}
		};

		const init = () => {
			const toDot = dotdotSelectors.join(', ');
			$(window).on('load resize', () => {
				truncateText(toDot);
			});
		};

		return {
			init
		};

	})();

	const arrowBtn = (() => {

		const arrowBtn = document.querySelector('.scroll-down'),
			scrollTarget = document.querySelector('.banner-catalog'),
			header = document.querySelector('.page-header');

		const getOffset = () => {
			try {
				return scrollTarget.offsetTop - header.offsetHeight + 'px';
			} catch (e) {
				return '650px';
			}
		};

		const scrollDown = () => {
			$('html, body').animate({
				scrollTop: getOffset()
			});
		};

		const addEvents = () => {
			if (arrowBtn) {
				arrowBtn.addEventListener('click', scrollDown);
			}
		};

		const init = () => {
			addEvents();
		};

		return {
			init
		};

	})();

	const productFilters = (() => {

		const $filters = $('.toolbar-sorter'),
			$filtersToggle = $filters.find('.button-toggle-filters'),
			activeClass = 'filters-open';

		const toggleFilters = () => {
			$filters.toggleClass(activeClass);
		};

		const addEvents = () => {
			$filtersToggle.on('click', toggleFilters);
		};

		const init = () => {
			addEvents();
		};

		return {
			init
		};

	})();

	const stickInSidebar = (() => {

        const stickyBlocks = [
            '.catalog-product-view .block-order',
            '.cms-page-view .block-advice',
            '.blog-page .block-advice',
        ];

        const selectors = {
            container: '.page-main',
            header: '.page-header',
            parent: '.sidebar',
            block: '.block'
        };

        const default_offset = 80;

        const getOffset = (selector) => {
            const $firstBlock = $(selector).closest(selectors.parent).find(selectors.block).first();
            const offsetAdjust = $firstBlock.css('paddingTop') || 0;
			return $(selectors.header).height() - parseInt(offsetAdjust) + 20 || default_offset;
        };

        const allowInViewport = (selector) => window.innerHeight > $(selector).closest(selectors.parent).height() + getOffset(selector);
        const canStickElement = (selector) => Sticky && !!document.querySelector(selector);

        const getOptions = (selector) => ({
            marginTop: getOffset(selector),
            stickyFor: 1023,
            stickyContainer: selectors.container,
            wrap: true
        });

        const initStickyItem = (selector) => {
            if (!allowInViewport(selector)) {
                return null;
            }
            const options = getOptions(selector);
            return new Sticky(selector, options);
        };

		const addEvents = selector => {
            if (!canStickElement(selector)) {
                return;
            }

            initStickyItem(selector);
		};

		const init = () => {
            $(document).ready(() => stickyBlocks.forEach((selector) => addEvents(selector)));
		};

		return {
			init
		};

	})();

	const hideButtons = (() => {

		const init = () => {
			const input = $('#newsletter');

			input.on({
				focus: () => {
					$('.block-order').hide();
				},
				blur: () => {
					$('.block-order').show();
				}
			});
		};

		return {
			init
		};

	})();

	const messages = (() => {

		const $messages = $('.messages');

		const closeMessages = () => {
			$messages.fadeOut(500, () => {
				$messages.remove();
			});
		};

		const init = () => {
			if ($messages.length) {
				document.body.addEventListener('click', closeMessages);
			}
		};

		return {
			init
		};

	})();

	const tooltip = (() => {

		const options = {
			arrow: true,
			duration: 400,
			maxWidth: 200,
			theme: 'tiptopol',
            animation: 'shift-away',
            allowHTML: true,
            content: (reference) => reference.getAttribute('title')
		};

		const init = selector => {
			if (selector) {
				tippy(selector, options);
			}
		};

		return {
			init
		};

	})();

	common.init();
	stickInSidebar.init();
	arrowBtn.init();
	productFilters.init();
	hideButtons.init();
	messages.init();
	tooltip.init('.tooltip');
});
