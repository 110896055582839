require(['jquery'],
  ($) => {

    'use strict';

    const addEvents = () => {
        $('#header-cart').on('change', '.product-item .details-qty .cart-item-qty', (e) => {
            const $button = $(e.target).next('.update-cart-item');
            if (!$button.length || $button.is(':hidden')) {
                return;
            }
            $button.click();
        });
    };

    const init = () => {
        addEvents();
    };

    init();

});
