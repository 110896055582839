require([
        'jquery'
    ],
    ($) => {

        'use strict';

        const account = (() => {
            const $cartImport = $('.cart-import');
            const init = () => {
                $cartImport.on('click', '.how-to-help', function (){
                    const $instruction = $cartImport.find('.instruction');
                    console.log($instruction);
                    $instruction.slideToggle();
                });
            };

            return {
                init
            };

        })();

        account.init();

    });
