require([
    'jquery'
  ],
  ($) => {

	"use strict";

	const menu = (() => {

        const header = document.querySelector('#header'),
		    $skipCart = $('.header-minicart'),
			$skipAccount = $(header).find('.skip-account'),
			$navButton = $(header).find('.skip-nav'),
			productsBtn = header && header.querySelector('.nav-products'),
			$miniCart = $skipCart.find('#header-cart'),
			body = document.body,
			menuSelector = '.nav-primary',
			openMenuClass = 'menu-open',
			openCartClass = 'minicart-open',
			openAccountClass = 'account-open',
			$menu = $(menuSelector),
			$categoryLinks = $(productsBtn).children('ul').children('li'),
            menuActiveClass = 'menu-active',
			itemCurrentClass = 'current',
			parentSelector = '.parent',
			mobileMenuMax = 1024,
			sidebarMenuSelector = '.block-layered-nav .block-content ul',
			$sidebarMenu = $(sidebarMenuSelector);

		let windowOffset = 0;

		const isMobile = () => {
			return window.innerWidth <= mobileMenuMax;
        };

		const toggleDocumentScroll = disable => {
			if (isMobile()) {
				if (disable) {
					document.ontouchmove = e => {
						const $target = $(e.target);
						if (!$target.parents('#header-cart').length || $target.find('.empty').length) {
							e.preventDefault();
						} else {
							return true;
						}
					};
				} else {
					document.ontouchmove = () => {
						return true;
					};
				}

			} else {
				document.ontouchmove = () => {
					return true;
				};
			}
		};

		const closeCart = () => {
			body.classList.remove(openCartClass);
		};

		const closeAccount = () => {
			body.classList.remove(openAccountClass);
		};

		const toggleCart = (isOpen) => {
			body.classList.toggle(openCartClass, isOpen);
            isOpen ? windowOffset = window.pageYOffset : window.scroll(0, windowOffset);
			toggleDocumentScroll(isOpen);
		};

		const toggleAccount = () => {
			$miniCart.toggleClass('skip-active');
			body.classList.toggle(openAccountClass);
			toggleDocumentScroll(body.classList.contains(openAccountClass));
		};

		const closeMenu = () => {
			body.classList.remove(openMenuClass);
			toggleDocumentScroll(false);
			closeAccount();
        };

		const closeAll = () => {
			closeMenu();
			closeAccount();
			closeCart();
			window.scroll(0, windowOffset);
		};

		const addOverlay = () => {
			let overlay = document.querySelector('.overlay');
			if (!overlay) {
				overlay = document.createElement('div');
				overlay.classList.add('overlay');
				overlay.addEventListener('click', closeAll);
				body.appendChild(overlay);
			}
		};

		const openMenu = () => {
			closeCart();
			addOverlay();
			closeAccount();
			body.classList.add(openMenuClass);
			windowOffset = window.pageYOffset;
			toggleDocumentScroll(true);

			if (!isMobile()) {
				const activeCategories = $categoryLinks.filter(`.${menuActiveClass}`);
				if (!activeCategories.length && $categoryLinks.length) {
					$categoryLinks[0].classList.add(menuActiveClass);
				}
			}
        };

		const openMenuOnLoad = () => {
            const $menuParent = $sidebarMenu.find('.has-active, .active').filter('.parent');
            if (!$menuParent.length) {
                return;
            }
            $menuParent.addClass(menuActiveClass);
		};

		const getMenuState = () => body.classList.contains(openMenuClass);
		const getAccountState = () => body.classList.contains(openAccountClass);

		const toggleMenu = () => {
			body.classList.contains(openMenuClass) ? closeMenu() : openMenu();
			window.scroll(0, windowOffset);
		};

		const isSidebar = ($menuItem) => {
			return $menuItem.parents('.sidebar').length;
		};

		const setParentHeight = ($parent) => {
			if ($parent.length) {
				const el = $parent[0],
					isActive = $parent.hasClass(menuActiveClass),
					rect = el.getBoundingClientRect(),
					elementOffset = rect.top,
					h = rect.height;

				if (isActive) {

					requestAnimationFrame(function () {
						el.style.height = window.innerHeight - elementOffset + 'px';
					});

					el.classList.add(itemCurrentClass);
					$parent.siblings().removeClass(itemCurrentClass);

					$parent.siblings().find(parentSelector).css({
						height: 'auto'
					});

					$parent.siblings().css({
						height: 'auto'
					});

					$parent.children('ul').css({
						height: window.innerHeight - elementOffset - h + 'px',
						maxHeight: window.innerHeight - elementOffset - h + 'px'
					});
				}

				else {
					el.style.height = 'auto';
					el.classList.remove(itemCurrentClass);
					$(el).closest('.level0').scrollTop(0);
				}
			}
		};

		const resetItemsHeight = () => {
            if (isMobile()) {
                return;
            }
			const $items = $(header).find(parentSelector);
			$items.each((i, item) => {
				if (item.style.height) {
					item.removeAttribute('style');
				}
			});
        };

		const toggleMenuItem = ($target, setActive) => {
			$target.siblings('li').removeClass(menuActiveClass);
			$target.find(parentSelector).removeClass(menuActiveClass);
			setActive ? $target.toggleClass(menuActiveClass, setActive) : $target.toggleClass(menuActiveClass);

			if (isMobile() && !isSidebar($target)) {
				setParentHeight($target);
			}
        };

		const allowMobileClick = e => {
			const $target = $(e.target);
			if (isMobile()) {
				return $target.is('a') ? $target.not('.level-top').length : $target.closest('a').not('.level-top').length;
			}
			return false;
		};

		const isProductMenu = e => {
			const $target = $(e.target);
			return $target.closest('.nav-products').length;
        };

        const disableClick = (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
        };

		const toggleItem = e => {
			const $target = $(e.target);
			const $parent = $target.closest('li').filter(parentSelector);
			const level = $target.parents('ul').length; // 0: .nav-primary, 1: main category, 2: subcategory, 3: product list link

            const linkItem = !($parent.length || (isProductMenu(e) && level < 3));

			if (linkItem || allowMobileClick(e)) {
				return;
            }

            const allowSidebarClick = isSidebar($parent) && $target.is('span');
            const allowCategoryClick = level === 1 && !isMobile() && !$parent.length;

             if (allowSidebarClick) {
                toggleMenuItem($parent, true);
                return;
            }

            if (allowCategoryClick) {
                return;
            }

			if (isMobile() || isSidebar($parent) || (!$parent.hasClass(menuActiveClass) && level !== 2)) {
                disableClick(e);
                toggleMenuItem($parent);
			}

			if ($parent.hasClass('nav-products') && !isMobile()) {
                disableClick(e);
				toggleMenu();
			}
		};

		const outsideMenuClick = () => {
			$('.page-header').on('click', e => {
				const menuOpened = getMenuState();
				const isList = e.target.classList.contains('level0');
				const accoutOpened = getAccountState();

				if (menuOpened && !isList && !isMobile()) {
					closeMenu();
				}
				if (accoutOpened && !isMobile() && e.target.classList.contains('page-header')) {
					closeAccount();
				}
			});
        };

		const onResize = () => {
			window.addEventListener('resize', () => {
                toggleDocumentScroll(false);
                resetItemsHeight();
			});
		};

        const onMinicartToggle = () => {
            $miniCart.on( "dropdowndialogopen", () => toggleCart(true));
            $miniCart.on( "dropdowndialogclose", () => toggleCart(false));
        };

        const disableHashInUrl = () => {
            $(document).on('click', 'a', (e) => {
                const href = e.target.getAttribute('href');
                if (href && href.startsWith('#')) {
                    e.preventDefault();
                }
            });
        };

        const onClick = () => {
            $navButton.on('click', toggleMenu);
			$menu.on('click', parentSelector, toggleItem);
            $sidebarMenu.on('click', parentSelector, toggleItem);
            $skipAccount.on('click', toggleAccount);
            outsideMenuClick();
            disableHashInUrl();
        };

		const addEvents = () => {
            onResize();
            onClick();
			openMenuOnLoad();
            onMinicartToggle();
		};

		const init = () => {
            addOverlay();
            addEvents();
		};

		return {
			init,
			openMenu,
			closeMenu,
			getMenuState,
			isMobile
		};

	})();

	menu.init();

});
